<template>
  <div class="detail-box">
    <div class="detail-nav">
      <page-tab :subNav="subNav"></page-tab>
    </div>
    <div class="detail-contents">
      <div class="article-title">
        <h3 class="title-text">{{detailMsg.title}}</h3>
        <div class="article-source">
          <p class="source-msg">
            <template v-if="detailMsg.externalSource&&detailMsg.externalSource!==''">来源：{{detailMsg.externalSource}}</template>
             {{detailMsg.publishDate}}
             <span class="read-num">阅读量：{{readNum}}</span>
          </p>
          <p class="share-article" v-if="!showIframe">
            <span class="share-tip">分享到：</span>
            <a id="share_weibo" class="weibo" @click="shareSina">
              <img class="share_icon" src="@/assets/img/detailImg/weibo.svg" alt="weibo" />
              <img class="share_icon active_icon" src="@/assets/img/detailImg/weibo_active.svg" alt="weibo" />
            </a>
            <a id="share_wechat" class="wechat">
              <img class="share_icon" src="@/assets/img/detailImg/wechat.svg" alt="wechat" />
              <img class="share_icon active_icon" src="@/assets/img/detailImg/wechat_active.svg" alt="wechat" />

              <span id="wechat_qrcode" class="wechart-qrcode" ref="wechartQr"></span>
            </a>
            <a id="share_qq" class="qq" @click="shareQQ"><!--  -->
              <img class="share_icon" src="@/assets/img/detailImg/qq.svg" alt="qq" />
              <img class="share_icon active_icon" src="@/assets/img/detailImg/qq_active.svg" alt="qq" />
              <span id="qq_qrcode" class="qq-qrcode" ref="qqQr"></span>
            </a>
          </p>
        </div>
      </div>
      <div class="simple-text" v-if="detailMsg.subTitle&&detailMsg.subTitle!==''">{{detailMsg.subTitle}}</div>
      <div v-if="!showIframe" class="article-text" v-html="detailMsg.content"></div>
      <p class="loading-data" v-if="showIframe && !lodingFinished">
        <img src="@/assets/img/article_list/loading.gif" />加载中...
      </p>
      <div v-if="showIframe" class="iframe-box" >
        <iframe 
          id="calendar"
          class="calendar"
          :class="lodingFinished ? '':'op'"
          :src="iframeSrc"
          frameborder="0"
          name="announce"
          @load="setIframe"
        ></iframe>
      </div>
      <div class="article-file">
        <p class="each-file" v-for="(file,index) in fileList" :key="index">
          <a :href="`${file.host}${file.url}`" download target="_blank">
            <img class="file-icon" :src="file.imgSrc" />
            <a class="file-name" >{{file.rawName}}</a>
            <a class="download-file" ></a>
          </a>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
// import '@/assets/css/other-detail'
// import axios from "axios";
import "@/assets/css/other-detail.less";
import QRCode from 'qrcodejs2'
import { otherDetail,getClickVolume } from '@/api/list';
import { setCatalogTree } from '@/utils/store-data'
import BuryingPont from "@/mixins/buryingPoint.js";
export default {
  mixins: [BuryingPont],
  data() {
    return {
      detailMsg: {},
      subNav: [],
      readNum: 0,
      fileList: [],
      typeArr: ['.mp4','.xls','.xlsx','.png','.jpg','.jpeg','.gif','.mp3','.doc','.docx','.dot','.wpt','.wps','.zip','.7z','.rar','.pdf','.html','.shtml','.docm','.xlsm','.xlsb','.dotx','.htm','.swf','.tiff','.tif','.webp','.cur','.svg','.txt','.rtf','.mht','.ppt','.pptx','.eml','.xml','.paper','.fil','.xsd','.bmp','.json','.gd','.et','.ett'],
      showIframe: false, // 是否展示iframe
      iframeSrc: '', // iframe的地址
      iframerReduceHeight: 800, // 减去iframe的高度
      lodingFinished: false
    };
  },
  computed:{
    pageDomain:function(){
      let origin = window.location.origin
      if (origin.indexOf('pictest') > -1) {
        return origin + '/hs'
      } else {
        return origin
      }
    }
  },
  created () {
    setCatalogTree(this.$store)
    // 详情
    var detailData = {
      // id: this.$route.params.id,
      catalogCode: this.$route.query.catalogCode,
      // catalogCode: this.$route.params.id,
      id: this.$route.query.id
    }
    otherDetail(detailData).then(res=>{
      this.detailMsg = res.data;
      this.subNav = res.data.catalogCodeList;
      this.fileList = res.data.uploadList;
      for(var i in this.fileList){
        for(var j in this.typeArr){
          if(this.confirmLast(this.fileList[i].rawName.toLowerCase(),this.typeArr[j])){
            this.fileList[i].imgSrc = this.fileKind(this.typeArr[j])
            break;
          }else{
            this.fileList[i].imgSrc = require("@/assets/img/detailImg/default.svg")
          }
        }
      }
      // 浏览详情页埋点
      this.sensors.track( 'LY_view_article_page' ,{
        AppName: this.$store.state.AppName, 
        page_id: '',
        content_sources: this.detailMsg.externalSource,
        release_time: this.detailMsg.publishDate,
        title: this.detailMsg.title,
        content_id: this.detailMsg.id,
        reading_quantity: this.readNum,
        is_login: false, 
        platform: this.$store.state.platform,
      })
    }).catch(()=>{});
    // 阅读量
    // getClickVolume({id: this.$route.params.id}).then(res=>{
    getClickVolume({id: this.$route.query.id}).then(res=>{
      this.readNum = res.data;
    }).catch(()=>{});
    const name = this.$route.query.name
    if (name) {
      this.showIframe = true
      if (name === '新华传播矩阵') {
        this.iframeSrc = this.pageDomain + '/ceis/list/cbjz/index.html'
        this.iframerReduceHeight = 600
      } else if (name === '经济智库') {
        this.iframeSrc = this.pageDomain + '/ceis/list/jjzk/index.html'
      } else if (name === '政务智库') {
        this.iframeSrc = this.pageDomain + '/ceis/list/xhzw/index.html'
      }
    }
  },
  mounted () {
    this.creatQrCode();
  },
  methods: {
    // 判断文件后缀名
    confirmLast(str, target) {
      var start = str.length-target.length;
      var arr = str.substr(start,target.length);
      if(arr == target){
          return true;
      }
      return false;
    },
    fileKind(type){
      var fileType = '';
      switch (type){
        case '.mp4':
          fileType = require("@/assets/img/detailImg/video.svg");
          break;
        case '.xls':
        case '.xlsx':
        case '.xlsm':
        case '.xlsb':
          fileType =  require("@/assets/img/detailImg/excel.svg");
          break;
        case '.png':
        case '.jpg':
        case '.jpeg':
        case '.gif':
          fileType =  require("@/assets/img/detailImg/img.svg");
          break;
        case '.svg':
          fileType =  require("@/assets/img/detailImg/svg.svg");
          break;
        case '.mp3':
          fileType =  require("@/assets/img/detailImg/mp3.svg");
          break;
        case '.doc':
        case '.docx':
        case '.dot':
        case '.dotx':
        case '.wpt':
        case '.wps':
        case '.docm':
          fileType =  require("@/assets/img/detailImg/word.svg");
          break;
        case '.zip':
        case '.7z':
        case '.rar':
          fileType =  require("@/assets/img/detailImg/zip.svg");
          break;
        case '.pdf':
          fileType =  require("@/assets/img/detailImg/pdf.svg");
          break;
        case '.html':
        case '.shtml':
        case '.htm':
          fileType =  require("@/assets/img/detailImg/html.svg");
          break;
        case '.txt':
          fileType =  require("@/assets/img/detailImg/txt.svg");
          break;
        case '.rtf':
          fileType =  require("@/assets/img/detailImg/rtf.svg");
          break;
        case '.mht':
          fileType =  require("@/assets/img/detailImg/mht.svg");
          break;
        case '.ppt':
          fileType =  require("@/assets/img/detailImg/ppt.svg");
          break;
        case '.ett':
          fileType =  require("@/assets/img/detailImg/ett.svg");
          break;
        case '.et':
          fileType =  require("@/assets/img/detailImg/et.svg");
          break;
        case '.pptx':
          fileType =  require("@/assets/img/detailImg/pptx.svg");
          break;
        case '.eml':
          fileType =  require("@/assets/img/detailImg/eml.svg");
          break;
        case '.xml':
          fileType =  require("@/assets/img/detailImg/xml.svg");
          break;
        case '.paper':
          fileType =  require("@/assets/img/detailImg/paper.svg");
          break;
        case '.bmp':
          fileType =  require("@/assets/img/detailImg/bmp.svg");
          break;
        case '.xsd':
          fileType =  require("@/assets/img/detailImg/xsd.svg");
          break;
        case '.json':
          fileType =  require("@/assets/img/detailImg/json.svg");
          break;
        case '.gd':
          fileType =  require("@/assets/img/detailImg/gd.svg");
          break;
        case '.swf':
          fileType =  require("@/assets/img/detailImg/swf.svg");
          break;
        case '.tiff':
          fileType =  require("@/assets/img/detailImg/tiff.svg");
          break;
        case '.tif':
          fileType =  require("@/assets/img/detailImg/tif.svg");
          break;
        case '.webp':
          fileType =  require("@/assets/img/detailImg/webp.svg");
          break;
        case '.cur':
          fileType =  require("@/assets/img/detailImg/cur.svg");
          break;
        default:
          fileType =  require("@/assets/img/detailImg/default.svg");
          break;
      }
      return fileType
    },
    // 查看文件
    checkFile(host,url){
      window.open(host+url,'_blank');
    },
    // 下载文件
    downloadFile(host,url){
      window.location.href = host+url;
    },
    // 分享到新浪微博
    shareSina(){
      this.sinaWeiBo(this.detailMsg.title);
    },
    sinaWeiBo(title, url, pic) {
      var param = {
        url: url || window.location.href,
        type: '3',
        count: '1', /** 是否显示分享数，1显示(可选)*/
        appkey: '', /** 您申请的应用appkey,显示分享来源(可选)*/
        title: title, /** 分享的文字内容(可选，默认为所在页面的title)*/
        pic: pic || '', /** 分享图片的路径(可选)*/ 
        ralateUid:'', /** 关联用户的UID，分享微博会@该用户(可选)*/
        rnd: new Date().valueOf()
      }
      var temp = [];
      for( var p in param ) {
          temp.push(p + '=' +encodeURIComponent( param[p] || '' ) )
      }
      var targetUrl = 'http://service.weibo.com/share/share.php?' + temp.join('&');
      window.open(targetUrl, 'sinaweibo', 'height=430, width=400');
    },
    // 微信分享二维码
    creatQrCode() {
      if (!this.$refs.wechartQr) return
      new QRCode(this.$refs.wechartQr, {
          text: window.location.href.replace("/ly/#/",'/mly/#/'), // 需要转换为二维码的内容
          width: 128,
          height: 128,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H
      });
      
      // new QRCode(this.$refs.qqQr, {
      //     text: window.location.href, // 需要转换为二维码的内容
      //     width: 128,
      //     height: 128,
      //     colorDark: '#000000',
      //     colorLight: '#ffffff',
      //     correctLevel: QRCode.CorrectLevel.H
      // });
    },
    //分享到QQ
    shareQQ() {
      // var shareUrl = null;
      // var that = this;
      // axios({//格式a
      //   method:'get',
      //   url:'https://api.985.so/api.php?format=json&url='+encodeURIComponent(window.location.href)+'&apikey=15928642493@4ab8df110bb634c40a638f973df88500'
      // }).then(function(resp){
      //   console.log(resp.data.url);
      //   shareUrl = resp.data.url;
      //   that.qqShare(that.detailMsg.title,shareUrl)
      // }).catch(resp => {
      //   console.log('请求失败：'+resp.status+','+resp.statusText);
      // });
      var shareUrl = window.location.href.replace("/ly/#/",'/mly/#/');
      this.qqShare(this.detailMsg.title,shareUrl);
    },
    // http://api.985.so/api.php
    qqShare: function (title, url, pic) {
      var param = {
        url: url || window.location.href,
        desc: '', /*分享描述*/
        title : title || '', /*分享标题(可选)*/
        summary : '',/*分享描述(可选)*/
        pics : pic || '',/*分享图片(可选)*/
        flash : '', /*视频地址(可选)*/
        site: '' /*分享来源 (可选) */
      };
      var s = [];
      for (var i in param) {
        if(i == 'url'){
          s.push(i + '=' + encodeURIComponent(param[i] || ''));
        }else{
          s.push(i + '=' + (param[i] || ''));
        }
      }
      var targetUrl = 'http://connect.qq.com/widget/shareqq/index.html?' + s.join('&') ;
      window.open(targetUrl, 'qq', 'height=520, width=720');
    },
    // 隐藏iframe导航之类的
    setIframe() {
      var calendar = window.document.querySelector('#calendar');
      if (calendar) {
        var calendarElDocument = calendar.contentWindow.document;
        var height = (calendarElDocument.documentElement.scrollHeight || calendarElDocument.body.scrollHeight) - this.iframerReduceHeight
        var top = calendarElDocument.querySelector(".xhcj-top");
        var line = calendarElDocument.querySelector(".xhcj-line");
        var footer = calendarElDocument.querySelector('.xhcj-footer');
        var cardContainer = calendarElDocument.querySelector('#js_card_container');
        var partner = calendarElDocument.querySelector('.xhcj-partner');
        var partnerCrumbs = calendarElDocument.querySelector('.xhcj-partner-crumbs');
        var partnerR = calendarElDocument.querySelector('.xhcj-partner-r');
        var cpfwNav = calendarElDocument.querySelector('.xhcj-cpfw-nav');
        if (top) {
          top.style.display = "none";
        }
        if (line) { 
          line.style.display = "none";
        } 
        if (footer){
          footer.style.display = "none"; 
        }
        if (partnerCrumbs){
         partnerCrumbs.style.opacity = 0;
        }
        if (cardContainer){
         cardContainer.style.display = "none";
        }
        if (partner){ 
          partner.style.background = "none";
        }
        if (partnerR){ 
          partnerR.style.margin = "0 150px 0 0";
        }
        if (cpfwNav){
          cpfwNav.style.display = "none";
        }
        if (height){
         calendar.style.height = height + "px";
        }
        setTimeout(()=> {
          this.lodingFinished = true
        }, 400)
      }
    }
  }
};
</script>

